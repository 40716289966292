import { ReactNode } from "react"

import { Container } from "@/ui/container"
import { Text } from "@/ui/text"
import { Layout } from "@/views/_layout"

type ErrorLayoutProps = {
  code: string | number
  children?: ReactNode
}

export default function ErrorLayout({ code, children }: ErrorLayoutProps) {
  return (
    <Layout>
      <Container css={{ py: "$8" }}>
        <Text as="h1" size="6xl" weight="bold" css={{ textAlign: "center" }}>
          {code}
        </Text>
        <Text
          as="h1"
          size="2xl"
          color="muted"
          weight="bold"
          css={{ textAlign: "center" }}
        >
          {children}
        </Text>
      </Container>
    </Layout>
  )
}
