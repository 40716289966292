import { Trans } from "@lingui/macro"

import ErrorLayout from "../_layout"

export default function ErrorPage404() {
  return (
    <ErrorLayout code={404}>
      <Trans id="errorPage.404.description">Stránka nenalezena</Trans>
    </ErrorLayout>
  )
}
